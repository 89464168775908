.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    padding: 30px 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

