.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    padding: 0 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    background-color: var(--background-primary);
}

.ContainerInner {
    padding: 10px 0;
    width: 100%;
    max-width: 700px;
}

.Instagram {
    position: absolute;
    top: 40px;
    right: 40px;
}

.Instagram img {
    width: 40px;
    font-size: 20px;
}

.LogoContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.Logo {
    height: 100px;
    cursor: pointer;
}

.Options {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Option {
    cursor: pointer;
    padding: 10px 40px;
}

.Option:hover {
    text-decoration: underline;
}

.OptionActive {
    font-weight: 600;
    text-decoration: underline;
}

.Button {
    background: none !important;
    border: 2px solid black !important;
    box-sizing: border-box !important;
    color: black !important;
    height: 40px !important;
    width: 120px !important;
    padding: 0 !important;
}
