.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    padding: 130px 20px 0 20px;
    box-sizing: border-box;
    position: sticky;
    height: 200px;
    top: -130px;
    left: 0;
    background-color: var(--background-primary);
    box-shadow: 0 4px 9px -6px rgba(0,0,0, 0.2);
}

.ContainerInner {
    padding: 10px 0;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LogoContainer {
    display: flex;
    justify-content: center;
}

.Logo {
    height: 60px;
    cursor: pointer;
}

.Options {
    display: flex;
    justify-content: space-between;
}

.Option {
    cursor: pointer;
    padding: 10px 40px;
}

.Option:hover {
    text-decoration: underline;
}

.OptionActive {
    font-weight: 600;
    text-decoration: underline;
}

.Button {
    background: none !important;
    border: 2px solid black !important;
    box-sizing: border-box !important;
    color: black !important;
    height: 40px !important;
    width: 120px !important;
    padding: 0 !important;
}
