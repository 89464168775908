.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Primary {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: url("../../assets/about/AboutBackground.png") repeat;
    background-size: contain;
    padding: 10px 0;
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    box-sizing: border-box;
}

.Column {
    width: 100%;
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
    text-align: center;
}

.Section {
    line-height: 1.4;
    margin: 10px 0 20px 0;
    background-color: #FEFBF5;
    padding: 20px;
    box-sizing: border-box;
}

.Title {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
}

.Paragraph {
    margin-bottom: 15px;
}

.FooterWrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 800px) {
    .ContainerInner {
        padding: 0 15px;
    }

    .Column {
        width: 100%;
    }

    .Section {
        margin-bottom: 20px;
    }
}
