.Container {
    display: flex;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    padding: 50px 0;
}

.Header {
    padding: 30px;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
}

.SectionOuter {
    width: 33%;
    padding: 10px;
    box-sizing: border-box;
}

.Section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    max-height: 350px;
    background-color: #ddd;
}

.Section img {
    width: 100%;
    transition: width .2s linear;
    object-fit: cover;
}

.Section img:hover {
    width: 105%;
    transition: width .2s linear;
    object-fit: cover;
}

.GradientOverlay {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0,0,0, .0), rgba(0,0,0, 1));
}

.TextOverlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 40px;

}

