.Container {
    max-width: 30%;
    margin: 0 1.5% 20px 1.5%;
    position: relative;
    border: 1px solid #ccc;
    text-align: center;
}

.ContainerClickable {
    cursor: pointer;
}

.ImageContainer {
    width: 100%;
    max-height: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.Container img {
    width: 100%;
    transition: width .2s linear;
    object-fit: cover;
}


.Container img:hover {
    width: 105%;
    transition: width .2s linear;
    object-fit: cover;
}

.Content {
    padding: 10px;
    box-sizing: border-box;
}

.Seperator {
    height: 1px;
    width: 80%;
    background-color: #ccc;
    margin: 20px 10%;
}

.Title {
    font-size: 23px;
}

.Subtitle {
    font-size: 16px;
    font-style: italic;
    margin-top: 10px;
}

.Price {
    margin-top: 15px;
    font-size: 35px;
}


@media (max-width: 1200px) {
    .Container {
        max-width: 35%;
    }
}


@media (max-width: 800px) {
    .Container {
        max-width: 40%;
    }
}


@media (max-width: 600px) {
    .Container {
        max-width: 80%;
    }
}
