.Container {
    width: 100%;
    background-color: #EEA9A9;
    position: relative;
}


.Testimonial {
    color: white;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.TestimonialHidden {
    display: none;
}


.TestimonialInner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 120px 0;
}

.ArrowLeftContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.ArrowRightContainer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
