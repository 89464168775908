.Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ContainerInner {
    max-width: 800px;
    width: 100%;
    padding: 20px 15px 100px 15px;
}

.Paragraph {
    margin: 10px 0;
}

.Step {
    margin: 10px 0 10px 20px;
    font-weight: 500;
}

.Note a {
    color: #b88114;
}

.Note {
    border: 1px solid #f2d08c;
    background-color: #fffcf3;
    box-sizing: border-box;
    padding: 20px;
    margin: 20px 0 50px 0;
    font-size: 16px;
}

.Title {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
}

.ButtonWrapper {
    display: flex;
    justify-content: center;
}
