.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    padding: 30px 15px;
}

.Showcase {
    display: flex;
    flex-wrap: wrap;
}

.ShowcaseOption {
    width: 25%;
    padding: 10px;
    box-sizing: border-box;
}

.ShowcaseOption img {
    width: 100%;
}

.ShowcaseOptionHeader {
    font-weight: 500;
    text-align: center;
    padding: 10px 0;
}

.FullList {
    width: 100%;
    background: url("../../assets/about/AboutBackground.png") repeat;
    background-size: contain;
}

.FullListInner {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.SectionTitle {
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin: 50px 0 20px 0;
}

.Section div {
    margin: 8px 0;
}

.Section {
    margin: 20px 0;
    background-color: #FEFBF5;
    padding: 20px;
    box-sizing: border-box;
}

@media (max-width: 800px) {
    .ContainerInner {
        max-width: 1200px;
        width: 100%;
        padding: 15px 15px;
    }

    .ShowcaseOption {
        width: 50%;
    }

    .SectionTitle {
        width: 100%;
        text-align: center;
        font-size: 30px;
        margin: 70px 0 40px 0;
    }
}
