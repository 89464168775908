.ContainerActive {
    position: fixed;
    left: 0 !important;
    z-index: 2000;
    transition: left .2s;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
}

.Container {
    position: fixed;
    background-color: var(--background-primary);
    width: 250px;
    max-width: 100vw;
    height: 100%;
    top: 0;
    left: -250px;
    z-index: 100;
    transition: left .2s;
    overflow: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
}

.Backdrop {
    background-color: black;
    opacity: .1;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
}

.Content {
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 20px;
    overflow-y: auto;
}

.NavOption {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    padding: 12px 15px;
    box-sizing: border-box;
    font-size: 18px;
    background: none;
    transition: background-color .05s linear, color .05s linear;
    font-weight: 500;
}

.NavOptionActive {
    font-weight: 600;
    text-decoration: underline;
}

.ButtonWrapper {
    padding: 12px 15px;
    box-sizing: border-box;
}
