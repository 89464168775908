.Container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
    padding: 50px 0;
}

.Header {
    font-size: 26px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-decoration: underline;
}

.SubDirections {
    margin-top: 15px;
}

.Left {
    width: 60%;
    padding: 40px;
    box-sizing: border-box;
}

.Right {
    width: 40%;
    padding: 20px;
    box-sizing: border-box;
}

.Right img {
    width: 100%;
}

.Section {
    margin-bottom: 40px;
}

.Button {
    height: 50px !important;
    width: 140px !important;
    padding: 0 !important;
    margin-left: auto;
    margin-right: auto;
}


.Left a {
    color: inherit;
    text-decoration: underline;
}
