.Container {
    border: none;
    outline: none;
    padding: 15px 30px;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    background-color: var(--color-primary);
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    opacity: 1;
    transition: opacity .1s linear;
}

.Container:hover {
    opacity: .8;
}

.Label {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
