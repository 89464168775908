.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    padding: 0 20px;
    box-sizing: border-box;
    position: sticky;
    height: 70px;
    top: 0;
    left: 0;
    background-color: var(--background-primary);
    box-shadow: 0 4px 9px -6px rgba(0,0,0, 0.2);
}

.ContainerInner {
    padding: 10px 0;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Hamburger {
    width: 30px;
    object-fit: contain;
    cursor: pointer;
}

.LogoContainer {
    display: flex;
    flex: 1;
    padding-left: 20px;
}

.Logo {
    height: 50px;
    cursor: pointer;
}

.Button {
    background: none !important;
    border: 2px solid black !important;
    box-sizing: border-box !important;
    color: black !important;
    font-weight: 600 !important;
    height: 40px !important;
    width: 120px !important;
    padding: 0 !important;
}
