.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.ContainerInner {
    width: 100%;
    max-width: 900px;
    padding: 20px; box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.Section {
    width: 33%;
    margin-bottom: 20px;
    padding: 0 15px;
    box-sizing: border-box;
}

.Header {
    font-weight: 500;
    margin-bottom: 10px;
}

.Content {
    line-height: 1.5;
    font-size: 18px;
}

.Content a {
    color: inherit;
    text-decoration: underline;
}

.FullSection {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.TextSmall {
    font-size: 16px;
}

.Instagram img {
    width: 40px;
    font-size: 20px;
}

.HoursButton {
    padding: 10px;
    border: 2px solid black;
    background: none;
    color: black;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 800px) {
    .Section {
        width: 100%;
        margin-bottom: 40px;
    }
}
