.Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ContainerInner {
    width: 100%;
    max-width: 1200px;
    padding-top: 30px;
}

.Pages {
    width: 100%;
    position: relative;
}

.Page {
    width: calc(100% - 60px);
    margin-left: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 1;
    -webkit-transition-property: opacity;
    -webkit-transition-duration: 300ms;
    transition: opacity 300ms linear;
}

.PageHidden {
    display: none;
}

.PageTransitioning {
    width: calc(100% - 60px);
    margin-left: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition-property: opacity;
    -webkit-transition-duration: 300ms;
    transition: opacity 300ms linear;
}

.Images {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ImageContainer {
    width: 33%;
    display: flex;
    justify-content: center;
}

.ImageContainer img {
    width: 97%;
}


.PageIndicatorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.PageIndicator {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 5px;
    background-color: rgba(0, 0, 0, .1);
}

.PageIndicatorActive {
    background-color: black;
}

.Arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 30px 15px;
}

.Arrow:hover {
    background-color: rgba(0, 0, 0, .05);
}

.Arrow img {
    height: 20px;
}

.ArrowRight {
    right: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ArrowLeft {
    left: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.Title {
    width: 100%;
    font-size: 30px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 500;
}

.Subtitle {
    width: 100%;
    text-align: center;
    font-size: 20px;
}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0;
}
