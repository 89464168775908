.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: url("../../../assets/about/AboutBackground.png") repeat;
    background-size: contain;
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}

.Section {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.ImageContainer {
    width: 30%;
    box-sizing: border-box;
}

.ImageContainer img {
    width: 100%;
}

.Content {
    background-color: var(--background-primary);
    padding: 40px;
    box-sizing: border-box;
    width: calc(70% - 40px);
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1.6;
}

.ContentLeft {
    margin-right: 40px;
}

.ContentRight {
    margin-left: 40px;
}

.Header {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 500;
}

@media (max-width: 800px) {
    .ContainerInner {
        padding: 15px;
    }

    .ImageContainer {
        width: 100%;
    }

    .Content {
        padding: 15px;
        width: 100%;
    }

    .ContentLeft {
        margin-right: 0;
    }

    .ContentRight {
        margin-left: 0;
    }
}
